import React from 'react'
import tw from 'twin.macro'

import Layout from '../components/layout/Layout'
import SEO from '../components/seo/SEO'
import PageHeader from '../components/styled/PageHeader'
import Images from '../components/images/Catering'

const Catering = () => (
  <Layout>
    <SEO title='Menu' />
    <PageHeader>Catering</PageHeader>
    <div css={tw`flex flex-auto flex-col justify-center`}>
      <p css={tw`max-w-xl text-center flex self-center pb-4`}>
        With 35 years experience, Sherry&apos;s continues to offer complete
        catering services with all the bells and whistles for office parties,
        rehearsal dinners and weddings including beautifully decorated cakes and
        fresh flowers. Call today for more info on Sherry&apos;s Catering!
      </p>
      <div css={tw`flex-wrap flex justify-center`}>
        <Images />
      </div>
    </div>
  </Layout>
)

export default Catering
